@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  font-family: "Quicksand", serif;
  font-optical-sizing: auto;
  background-color: rgba(0, 0, 0, .9);
}

.welcome {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 85%;
  color: white;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}

.mainPageHeader {
  font-size: 38px;
  font-weight: 350;
}

.mainPageDescriptor {
  font-size: 28px;
  font-weight: 300;
}

@media only screen and (max-width: 900px) {
  .welcome {
    width: 100%;
    padding: 50px;
  }
  
  .mainPageHeader {
    font-size: 28px;
    font-weight: 350;
  }
  
  .mainPageDescriptor {
    font-size: 18px;
    font-weight: 300;
  }
}

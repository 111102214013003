@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.contactLinksBody {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 15px;
  margin-top: 15px; 
}

.contactLinksBody div svg {
  width: 25px;
  height: 25px;
}

svg:hover {
  transform: scale(1.5);
}

@media only screen and (max-width: 900px) {
  .contactLinksBody {
    width: 20%
  }
}
